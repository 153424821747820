import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import customization from './customization';
import station from './station';
import status from './status';
import information from './information';

const rootReducer = combineReducers({
  customization,
  station,
  status,
  information,
});

export default (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunkMiddleware),
);
