import { Responsive, Segment } from 'semantic-ui-react';

const styles = {
  containerDesktop: {
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    padding: '0px 16px 0px 16px',
  },
  containerMobile: {
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    padding: '0px 12px 0px 12px',
  },
  title: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
    paddingLeft: '8px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.12px',
  },
};
Object.freeze(styles);

function UserIcon(props) {
  const { title } = props;
  return (
    <div>
      <Responsive as={Segment} minWidth={601} style={styles.containerDesktop}>
        <svg
          fill="white"
          height="24px"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M16 4a5 5 0 1 1-5 5 5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm10 28h-2v-5a5 5 0 0 0-5-5h-6a5 5 0 0 0-5 5v5H6v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z" />
        </svg>
        <p style={styles.title}>{title}</p>
      </Responsive>
      <Responsive as={Segment} maxWidth={600} style={styles.containerMobile}>
        <svg
          fill="white"
          height="24px"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M16 4a5 5 0 1 1-5 5 5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm10 28h-2v-5a5 5 0 0 0-5-5h-6a5 5 0 0 0-5 5v5H6v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z" />
        </svg>
      </Responsive>
    </div>
  );
}

export default UserIcon;
