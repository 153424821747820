const zoomLevelCalculationViaDistances = (
  measureScreenInCm,
  measureEarthInMeter,
) => {
  let zoomLevel = Math.log(measureEarthInMeter / measureScreenInCm) / Math.log(2.0) - 1.0;
  zoomLevel += 1;

  if (zoomLevel < 1.5) {
    zoomLevel = 0.0;
  } else {
    zoomLevel = Math.round(zoomLevel + 0.3);
  }

  return zoomLevel;
};

export default zoomLevelCalculationViaDistances;
