function Close() {
  return (
    <svg
      style={{
        verticalAlign: 'middle',
        float: 'right',
        cursor: 'pointer',
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
    >
      <path
        fill="#231f20"
        d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4z"
      />
    </svg>
  );
}

export default Close;
