import { connect } from 'react-redux';
import styled from 'styled-components';

const styles = {
  title: {
    color: '#222222',
    maxWidth: '291px',
    fontSize: '20px',
    fontWeight: '600',
    paddingLeft: '15px',
    marginRight: '40px',
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
};
Object.freeze(styles);

function Title(props) {
  const { data } = props;
  if (data === null) return null;

  return (
    <DataFadeIn>
      <div id="datafadeIn" style={styles.title}>
        <span>{data.name}</span>
      </div>
    </DataFadeIn>
  );
}

const DataFadeIn = styled.div`
#datafadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const informationStateToProps = (state) => ({
  is_fetching: state.information.is_fetching,
  data: state.information.data,
});

export default connect(informationStateToProps)(Title);
