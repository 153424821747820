function ConnectorIcon(props) {
  const { type, iconColor } = props;

  switch (type) {
    case 'Mennekes':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M18.65 3c4.618 0 7.65 3.59 7.65 9.65 0 6.824-4.984 12.15-12.15 12.15S2 19.474 2 12.65C2 6.59 5.032 3 9.65 3zm0 1.3h-9c-3.847 0-6.35 2.964-6.35 8.35 0 6.124 4.422 10.85 10.85 10.85S25 18.774 25 12.65c0-5.386-2.503-8.35-6.35-8.35zM10.5 16.35a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm7 0a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm-7 1.3a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7zm7 0a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7zM7 10.85a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm7 0a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm7 0a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm-14 1.3a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7zm7 0a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7zm7 0a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7zM11 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm6 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm-6 1.3a.7.7 0 1 0 0 1.4.7.7 0 0 0 0-1.4zm6 0a.7.7 0 1 0 0 1.4.7.7 0 0 0 0-1.4z" />
          </g>
        </svg>
      );
    case 'CCS':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M18 20a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm-8 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm8 1.2a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6zm-8 0a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6zM17.094 2c3.206 0 5.312 2.494 5.312 6.688 0 4.72-3.449 8.406-8.406 8.406-4.957 0-8.406-3.687-8.406-8.407C5.594 4.495 7.7 2 10.906 2h6.188zm0 1.3h-6.188c-2.466 0-4.012 1.914-4.012 5.388 0 4.091 2.955 7.106 7.106 7.106 4.15 0 7.106-3.015 7.106-7.107 0-3.473-1.546-5.387-4.012-5.387zM19 18c1.933 0 3.5 1.79 3.5 4s-1.567 4-3.5 4H9c-1.933 0-3.5-1.79-3.5-4s1.567-4 3.5-4h10zm0 1.3H9c-1.174 0-2.2 1.173-2.2 2.7 0 1.527 1.026 2.7 2.2 2.7h10c1.174 0 2.2-1.173 2.2-2.7 0-1.527-1.026-2.7-2.2-2.7zM12 11a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm4 0a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm-4 1.1a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3zm4 0a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3zM9.75 7.25a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm4.25 0a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm4.25 0a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm-8.5 1.1a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3zm4.25 0a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3zm4.25 0a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3zM16 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-4 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm4 1a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zm-4 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z" />
          </g>
        </svg>
      );
    case 'CHAdeMO':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M14 1.85c2.74 0 5.268.907 7.3 2.437l.7-.701L24.414 6l-.7.7A12.096 12.096 0 0 1 26.15 14c0 6.71-5.44 12.15-12.15 12.15S1.85 20.71 1.85 14c0-2.74.907-5.267 2.436-7.3l-.7-.7L6 3.586l.7.7A12.096 12.096 0 0 1 14 1.85zm0 1.3C8.008 3.15 3.15 8.008 3.15 14S8.008 24.85 14 24.85 24.85 19.992 24.85 14 19.992 3.15 14 3.15zm0 14.2a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm.833 1.463l-.833.834-.833-.834-.354.354.834.833-.834.833.354.354.833-.834.833.834.354-.354-.834-.833.834-.833-.354-.354zM8.625 10.35a3.65 3.65 0 1 1 0 7.3 3.65 3.65 0 0 1 0-7.3zm10.375 0a3.65 3.65 0 1 1 0 7.3 3.65 3.65 0 0 1 0-7.3zm-10.375 1.3a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7zm10.375 0a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7zm-5-6.3a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3zm.833 1.463L14 7.647l-.833-.834-.354.354.834.833-.834.833.354.354.833-.834.833.834.354-.354L14.353 8l.834-.833-.354-.354z" />
          </g>
        </svg>
      );
    case 'Schuko':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M14.5 4.497l.555.061a9.496 9.496 0 0 1 6.861 4.188l.084.126V12.5h1v3h-1v3.628l-.084.126a9.496 9.496 0 0 1-6.861 4.188l-.555.061V22.5h-1v1.003l-.555-.061a9.496 9.496 0 0 1-6.861-4.188L6 19.128V15.5H5v-3h1V8.872l.084-.126a9.496 9.496 0 0 1 6.861-4.188l.555-.061V5.5h1V4.497zm1 1.135V6.5h-3v-.868A8.5 8.5 0 0 0 7 9.177L6.999 12.5H7v3h-.001L7 18.823a8.5 8.5 0 0 0 5.5 3.545V21.5h3v.868a8.5 8.5 0 0 0 5.5-3.545V9.177a8.5 8.5 0 0 0-5.5-3.545zm2 6.868a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-7 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
          </g>
        </svg>
      );
    case 'Type 1':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M14 4c5.799 0 10.5 4.701 10.5 10.5 0 5.29-3.911 9.665-8.999 10.394l-.001.906h-3v-.906c-5.089-.728-9-5.104-9-10.394C3.5 8.701 8.201 4 14 4zm0 1.3a9.2 9.2 0 1 0 0 18.4 9.2 9.2 0 0 0 0-18.4zm0 12.2a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 1.3a.7.7 0 1 0 0 1.4.7.7 0 0 0 0-1.4zm-5.25-3.3a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm10.5 0a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5zm-10.5 1.3a.45.45 0 1 0 0 .9.45.45 0 0 0 0-.9zm10.5 0a.45.45 0 1 0 0 .9.45.45 0 0 0 0-.9zM17.5 8.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm-7 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm7 1.3a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4zm-7 0a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4zm5-7.8v1.3h-3V2h3z" />
          </g>
        </svg>
      );
    case 'Type J':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M24.593 14l-5.01-5.062H8.417L3.407 14l5.01 5.062h11.166L24.593 14zM20 7.938L26 14l-6 6.062H8L2 14l6-6.062h12zM14 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm4.5-4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-9 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
          </g>
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none" fillRule="nonzero">
            <path d="M0 0h28v28H0z" />
            <path fill={iconColor} d="M9.5 3.35c-4.618 0-7.65 3.59-7.65 9.65 0 6.824 4.984 12.15 12.15 12.15S26.15 19.824 26.15 13c0-6.06-3.032-9.65-7.65-9.65h-9zm9 1.3c3.847 0 6.35 2.964 6.35 8.35 0 6.124-4.422 10.85-10.85 10.85S3.15 19.124 3.15 13c0-5.386 2.503-8.35 6.35-8.35h9zm-3.932 10.722h-1.476l-.12-1.956h.984c.672 0 1.188-.384 1.188-1.044 0-.612-.42-1.02-1.056-1.02-.636 0-1.056.372-1.104.948H11.1c.096-1.548 1.104-2.604 2.928-2.604 1.68 0 2.916 1.092 2.916 2.676 0 1.272-.9 2.112-2.316 2.304l-.06.696zm-.792 2.712c-.564 0-1.044-.432-1.044-1.02 0-.564.48-.996 1.044-.996.576 0 1.056.432 1.056.996 0 .588-.48 1.02-1.056 1.02z" />
          </g>
        </svg>
      );
  }
}

export default ConnectorIcon;
