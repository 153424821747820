import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import GeneralInfoPreloader from '../../assets/sidebar/GeneralInfoPreloader';
import Clock from '../../assets/clock';

const styles = {
  address: {
    fontSize: '14px',
    padding: '2px 0 0 15px',
    fontWeight: 'normal',
    color: '#646464',
    maxWidth: '291px',
    lineHeight: '1.2',
    letterSpacing: '0.2px',
  },
  clock: {
    padding: '15px 0 9px 15px',
  },
  openHours: {
    display: 'inline-block',
    paddingLeft: '4px',
    color: '#646464',
    fontSize: '13px',
    fontWeight: '400',
    verticalAlign: 'text-top',
    lineHeight: '1.38',
    letterSpacing: '-0.12px',
    maxWidth: '257px',
  },
};
Object.freeze(styles);

function GeneralInfo(props) {
  const { data } = props;

  if (_.isEmpty(data)) {
    return <GeneralInfoPreloader />;
  }

  const { address, city, openHours } = data;

  const addressText = _.isEmpty(address)
    ? '-'
    : `${address}, `;
  const cityText = _.isEmpty(city) ? '-' : city;

  const openHoursText = _.isEmpty(openHours)
    ? '-'
    : openHours;

  return (
    <DataFadeIn>
      <div id="datafadeIn">
        <div style={styles.address}>{addressText + cityText}</div>
        <div style={styles.clock}>
          <Clock />
          <div style={styles.openHours}>
            {openHoursText}
          </div>
        </div>
      </div>
    </DataFadeIn>
  );
}

const DataFadeIn = styled.div`
#datafadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const informationStateToProps = (state) => ({
  is_fetching: state.information.is_fetching,
  data: state.information.data,
});

export default connect(informationStateToProps)(GeneralInfo);
