import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import bg from './bg.json'; // Bulgarian Български  1
import cs from './cs.json'; // Czech Čeština        2
import da from './da.json'; // Danish Dansk         3
import de from './de.json'; // German Deutsch       4
import el from './el.json'; // Greek Ελληνικά       5
import en from './en.json'; // English English      6
import es from './es.json'; // Spanish Español      7
import et from './et.json'; // Estonian Eesti       8
import fi from './fi.json'; // Finnish Suomi        9
import fr from './fr.json'; // French Français      10
import hu from './hu.json'; // Hungarian Magyar     11
import is from './is.json'; // Icelandic Íslenska   12
import it from './it.json'; // Italian Italiano     13
import nb from './nb.json'; // Norwegian Norsk      14
import pl from './pl.json'; // Polish Polski        15
import ro from './ro.json'; // Romanian Română      16
import ru from './ru.json'; // Russian Русский      17
import sk from './sk.json'; // Slovak Slovenský     18
import sv from './sv.json'; // Swedish Svenska      19
import th from './th.json'; // Thai Thai            20
import sr from './sr.json'; // Serbian Srpski       21
import hr from './hr.json'; // Croatian Hrvatski    22

const resources = {
  bg: {
    translation: bg,
  },
  cs: {
    translation: cs,
  },
  da: {
    translation: da,
  },
  de: {
    translation: de,
  },
  el: {
    translation: el,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  et: {
    translation: et,
  },
  fi: {
    translation: fi,
  },
  fr: {
    translation: fr,
  },
  hr: {
    translation: hr,
  },
  hu: {
    translation: hu,
  },
  is: {
    translation: is,
  },
  it: {
    translation: it,
  },
  nb: {
    translation: nb,
  },
  pl: {
    translation: pl,
  },
  ro: {
    translation: ro,
  },
  ru: {
    translation: ru,
  },
  sk: {
    translation: sk,
  },
  sv: {
    translation: sv,
  },
  sr: {
    translation: sr,
  },
  th: {
    translation: th,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
