import convertWebappStringToLatLngObject from '../../../utils/geometry';
import { legacyHost } from './legacy';
import { BrandConfig } from './brand-mgmt';

const {
  REACT_APP_VIRTA_DEFAULT_CENTERPOINT,
  REACT_APP_GOOGLEPLAY_LINK,
  REACT_APP_APPSTORE_LINK,
} = process.env;
const defaultCenterPoint = convertWebappStringToLatLngObject(REACT_APP_VIRTA_DEFAULT_CENTERPOINT);

const defaultState = {
  is_fetching: false,
  brandColor: '#cccccc',
  brandName: 'virta',
  logo: null,
  googlePlayStoreLink: REACT_APP_GOOGLEPLAY_LINK,
  appleAppStoreLink: REACT_APP_APPSTORE_LINK,
  brandCode: 'virta',
  parkedUrl: 'virta.webapp.virtaglobal.com',
  defaultLocale: 'en',
  webAppCenter: defaultCenterPoint,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  const isBrandMgmt = window.location.pathname.startsWith('/map');
  if (isBrandMgmt) {
    return BrandConfig(state, action);
  }

  return legacyHost(state, action);
};
