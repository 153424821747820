import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import GooglePlay from '../../assets/GooglePlay';
import AppStore from '../../assets/AppStore';

const styles = {
  titleConatainer: {
    paddingTop: '36px',
  },
  appContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

function MobileApplicationLinks(props) {
  const {
    data,
    title,
    appStore,
    googlePlay,
  } = props;

  if (_.isNil(data) || _.isNil(googlePlay) || _.isNil(appStore)) return null;
  return (
    <DataFadeIn>
      <div id="datafadeIn">
        <p style={styles.titleConatainer}>{title}</p>
        <div style={styles.appContainer}>
          <a href={appStore} target="_blank" rel="noopener noreferrer">
            <AppStore />
          </a>
          <a href={googlePlay} target="_blank" rel="noopener noreferrer">
            <GooglePlay />
          </a>
        </div>
      </div>
    </DataFadeIn>
  );
}

const DataFadeIn = styled.div`
#datafadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const informationStateToProps = (state) => ({
  is_fetching: state.information.is_fetching,
  data: state.information.data,
});

export default connect(informationStateToProps)(MobileApplicationLinks);
