import styled from 'styled-components';

const styles = {
  footer: {
    position: 'fixed',
    height: '56px',
    textDecoration: 'none',
  },
  footerText: {
    textDecoration: 'none',
    color: 'white',
    padding: '15px 0 15px 15px',
    fontSize: '17px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
  },
};
Object.freeze(styles);

function Footer(props) {
  const {
    width, title,
  } = props;

  return (
    <Container>
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...styles.footer, width }}
        id="footer"
      >
        <div id="footerBackground" style={{ ...styles.footerText }}>
          {title}
        </div>
      </a>
    </Container>
  );
}

const Container = styled.div`
  .footerHover:hover {
    height: 56px;
    background-color: rgb(255, 255, 255, .3) !important;
  }
  .footerDisabled {
    height: 56px;
    background-color: rgb(255, 255, 255, .7) !important;
  }
  @-webkit-keyframes animateKeyFrames {
    0% {bottom: 0px;}
    50% {bottom: -32px;}
    100% {bottom: 0px;}
  }

  @keyframes animateKeyFrames {
    0% {bottom: 0px;}
    50% {bottom: -32px;}
    100% {bottom: 0px;}
  }
`;

export default Footer;
