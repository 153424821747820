import { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { Marker } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import _ from 'lodash';
import { Responsive, Segment, Container } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import createStore from '../store';
import fetchInformation from '../store/information/action/fetchInformation';
import available from '../assets/location_available.svg';
import inUse from '../assets/location_in_use.svg';
import inactive from '../assets/location_inactive.svg';
import Images from './sidebar/Images';
import Title from './sidebar/Title';
import GeneralInfo from './sidebar/GeneralInfo';
import Stations from './sidebar/Stations';
import MobileApplicationLinks from './sidebar/MobileApplictionLinks';
import Footer from './sidebar/Footer';
import Close from '../assets/close';

const icon = [inactive, available, inUse];

const styles = {
  container: {
    position: 'absolute',
    width: '0',
    backgroundColor: '#edeff1',
    boxShadow: '2px 0 0 0 rgba(0, 0, 0, 0.12)',
  },
  info: {
    boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.3)',
  },
  stationContainer: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
  },
  mobileApplicationText: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '15px',
    paddingRight: '50px',
    paddingBottom: '64px',
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    right: '12px',
  },
};
Object.freeze(styles);

class Markers extends Component {
  constructor() {
    super();
    const store = createStore();
    this.state = {
      store,
    };
  }

  callForGettingInformation(id) {
    const { store } = this.state;
    if (id !== null) {
      store.dispatch(fetchInformation(id));
    }
  }

  render() {
    const {
      map,
      stations,
      checkIFrame,
      t,
      googlePlayStoreLink,
      appleAppStoreLink,
      brandColorPreview,
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const hostColor = (brandColorPreview !== false) ? brandColorPreview : this.props.hostColor;

    const { store } = this.state;

    if (_.isNil(stations)) {
      return null;
    }

    const pageHeight = window.screen.height;

    const statuseTranslations = {
      availbale: t('EVSEStatuses.available'),
      inUse: t('EVSEStatuses.inUse'),
      disabled: t('EVSEStatuses.disabled'),
    };

    const onMarkerClick = (centerPoint) => {
      map.context[MAP].panTo(centerPoint);

      const evseSection = document.querySelectorAll('[id^=evseSection]');
      for (let i = 0; i < evseSection.length; i += 1) {
        evseSection[i].style.boxShadow = 'none';
        evseSection[i].style.boxSizing = 'none';
        evseSection[i].style.borderRadius = '0';
      }
    };

    const openNav = (stationId, mode) => {
      this.callForGettingInformation(stationId);

      let width = '375px';
      if (mode === 'Desktop') {
        document.getElementById('stationSidebar').style.left = '0';
        document.getElementById('stationSidebar').style.transition = 'left 0.3s ease-out';
      } else {
        width = '100%';
        document.getElementById('stationSidebar').style.bottom = '0';
        document.getElementById('stationSidebar').style.transition = 'bottom 0.3s ease-out';
      }

      document.getElementById('stationSidebar').style.width = width;
      document.getElementById('footer').style.bottom = '-56px';
      document.getElementById('footer').style.transition = 'bottom 0s';
      if (mode !== 'Desktop') {
        (document.getElementById('navbar').style.display = 'none');
      } else {
        (document.getElementById('navbar').style.display = 'block');
      }
    };

    const closeNav = (mode) => {
      if (mode === 'Desktop') {
        document.getElementById('stationSidebar').style.left = '-375px';
        document.getElementById('stationSidebar').style.transition = 'left 0.3s ease-in';
      } else {
        document.getElementById('stationSidebar').style.top = null;
        document.getElementById('stationSidebar').style.position = 'fixed';
        document.getElementById('stationSidebar').style.bottom = `-${pageHeight}px`;
        document.getElementById('stationSidebar').style.transition = 'bottom 0.3s ease-in';
      }

      document.getElementById('navbar').style.display = 'block';
      document.getElementById('footer').style.bottom = '-56px';
    };

    let marginFromTop = '64px';
    if (checkIFrame === true) {
      marginFromTop = '0';
    }

    const markers = [];
    stations.map((station) => markers.push(
      <Container key={station.id}>
        <Responsive as={Segment} minWidth={600}>
          <Marker
            key={station.id}
            position={{ lat: station.latitude, lng: station.longitude }}
            icon={{
              url: icon[station.status],
            }}
            onClick={() => {
              onMarkerClick({
                lat: station.latitude,
                lng: station.longitude,
              });
              openNav(station.id, 'Desktop');
            }}
          />
        </Responsive>
        <Responsive as={Segment} maxWidth={599}>
          <Marker
            key={station.id}
            position={{ lat: station.latitude, lng: station.longitude }}
            icon={{
              url: icon[station.status],
            }}
            onClick={() => {
              onMarkerClick({
                lat: station.latitude,
                lng: station.longitude,
              });
              openNav(station.id, 'Mobile');
            }}
          />
        </Responsive>
      </Container>,
    ));
    return (
      <div>
        {markers}
        <Responsive
          as={Segment}
          minWidth={600}
          id="stationSidebar"
          style={{
            ...styles.container,
            top: marginFromTop,
            left: '-375px',
            bottom: '0',
            overflow: 'auto',
          }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Provider store={store}>
              <Images />
              <div
                style={{
                  ...styles.info,
                  paddingTop: '14px',
                  backgroundColor: '#fafafa',
                }}
              >
                <div style={{ position: 'relative' }}>
                  <Title />
                  <CloseBtn
                    style={styles.closeBtn}
                    onClick={() => { closeNav('Desktop'); }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <Close />
                  </CloseBtn>
                  <GeneralInfo />
                </div>
              </div>
              <div style={{ ...styles.stationContainer }}>
                <Stations
                  title={t('stationSidebar.evseSelectionTitle')}
                  brandColor={hostColor}
                  otpDisabledBtn={t('stationSidebar.RegisterToChargeHere')}
                  otpActiveBtn={t('stationSidebar.start')}
                  statusText={statuseTranslations}
                />
              </div>
              <div style={{ ...styles.mobileApplicationText }}>
                <MobileApplicationLinks
                  title={t('stationSidebar.mobileAppsDesc')}
                  googlePlay={googlePlayStoreLink}
                  appStore={appleAppStoreLink}
                />
              </div>
              <div>
                <Footer
                  title={t('stationSidebar.start')}
                  width="375px"
                />
              </div>
            </Provider>
          </div>
        </Responsive>
        <Responsive
          as={Segment}
          maxWidth={599}
          id="stationSidebar"
          style={{
            ...styles.container,
            height: '100%',
            bottom: `-${pageHeight}px`,
            overflow: 'auto',
          }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Provider store={store}>
              <Images />
              <div
                style={{
                  ...styles.info,
                  paddingTop: '14px',
                  backgroundColor: '#fafafa',
                }}
              >
                <div style={{ position: 'relative' }}>
                  <Title />
                  <CloseBtn
                    style={styles.closeBtn}
                    onClick={() => { closeNav('Mobile'); }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <Close />
                  </CloseBtn>
                  <GeneralInfo />
                </div>
              </div>
              <div style={{ ...styles.stationContainer }}>
                <Stations
                  title={t('stationSidebar.evseSelectionTitle')}
                  brandColor={hostColor}
                  otpDisabledBtn={t('stationSidebar.RegisterToChargeHere')}
                  otpActiveBtn={t('stationSidebar.start')}
                  statusText={statuseTranslations}
                />
              </div>
              <div>
                <div style={{ ...styles.mobileApplicationText }}>
                  <MobileApplicationLinks
                    title={t('stationSidebar.mobileAppsDesc')}
                    googlePlay={googlePlayStoreLink}
                    appStore={appleAppStoreLink}
                  />
                </div>
              </div>
            </Provider>
            <Footer
              title={t('stationSidebar.start')}
              width="100%"
            />
          </div>
        </Responsive>
      </div>
    );
  }
}

const CloseBtn = styled.div`
  :focus {
    outline: 0;
  }
`;

const mapStateToProps = (state) => ({
  stations: state.status.data,
  googlePlayStoreLink: state.customization.host.googlePlayStoreLink,
  appleAppStoreLink: state.customization.host.appleAppStoreLink,
  hostColor: state.customization.host.brandColor,
});

export default withTranslation()(connect(mapStateToProps)(Markers));
