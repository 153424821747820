const zoomLevelMax = 22.0;

const GeoTransformLat = (source) => -source + 90.0;

const GeoTransformLng = (source) => source + 180.0;

const MapBlockDivisionsCountLat = (zoomLevel) => (2.0 ** (zoomLevelMax - zoomLevel - 1));

const MapBlockDivisionsCountLng = (zoomLevel) => (2.0 ** (zoomLevelMax - zoomLevel));

export const MapBlockKeyIndexLatFrom = (lat, zoomLevel) => Math.floor(
  (GeoTransformLat(lat) / (2.0 * 90.0)) * MapBlockDivisionsCountLat(zoomLevel),
);

export const MapBlockKeyIndexLngFrom = (lng, zoomLevel) => Math.floor(
  (GeoTransformLng(lng) / (2.0 * 180.0))
      * MapBlockDivisionsCountLng(zoomLevel),
);
