import styled from 'styled-components';

const Container = styled.div`
  .preloading {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #e1e1e1;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
  }
  @keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 1px
    }
  }
`;

const styles = {
  container: {
    height: '86px',
    paddingLeft: '15px',
  },
  topRectangle: {
    width: '180px',
    height: '14px',
    borderRadius: '4px',
    backgroundColor: '#e9e9e9',
  },
  bottomRectangle: {
    marginTop: '9px',
    width: '90px',
    height: '10px',
    borderRadius: '4px',
    backgroundColor: '#e9e9e9',
  },
};
Object.freeze(styles);

function GeneralInfoPreloader() {
  return (
    <Container>
      <div style={styles.container}>
        <div className="preloading" style={styles.topRectangle} />
        <div className="preloading" style={styles.bottomRectangle} />
      </div>
    </Container>
  );
}

export default GeneralInfoPreloader;
