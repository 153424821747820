import {
  CUSTOMER_LINKS_REQUEST,
  CUSTOMER_LINKS_SUCCESS,
  CUSTOMER_LINKS_FAILURE,
} from '../types';

export const createCustomerLinksRequestAction = () => ({ type: CUSTOMER_LINKS_REQUEST });
export const createCustomerLinksSuccessAction = (brandCustomerLinks) => ({
  type: CUSTOMER_LINKS_SUCCESS,
  brandCustomerLinks,
});
export const createCustomerLinksFailureAction = (error) => ({
  type: CUSTOMER_LINKS_FAILURE,
  error,
});
