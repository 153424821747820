import {
  BRAND_CONFIG_FAILURE,
  BRAND_CONFIG_REQUEST,
  BRAND_CONFIG_SUCCESS,
} from '../types';

export const createBrandConfigRequestAction = () => ({
  type: BRAND_CONFIG_REQUEST,
});
export const createBrandConfigSuccessAction = (config) => ({
  type: BRAND_CONFIG_SUCCESS,
  config,
});
export const createBrandConfigFailureAction = (error) => ({
  type: BRAND_CONFIG_FAILURE,
  error,
});
