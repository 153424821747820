export const paddingCalculationForClusters = (number) => {
  let count = 0;

  let nmb = number;

  if (number >= 1) count += 1;

  while (nmb / 10 >= 1) {
    nmb /= 10;
    count += 1;
  }

  if (count < 2) return '8px 12px';
  if (count < 3) return '11px 11px';
  if (count < 4) return '16px 12px';
  if (count < 5) return '21px 13px';
  return '26px 14px';
};

export default paddingCalculationForClusters;
