import { connect } from 'react-redux';
import {
  Grid,
  Image,
  Responsive,
  Segment,
} from 'semantic-ui-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserIcon from '../assets/user';
import MapIcon from '../assets/map';
import UserRegisterIcon from '../assets/user_register';
import GlobeIcon from '../assets/globe';
import i18next from '../locales';

const styles = {
  navbar: {
    position: 'fixed',
    width: '100%',
    height: '64px',
    boxShadow: '0 2px 0 0 rgba(0, 0, 0, 0.12)',
  },
  navbarRow: {
    display: 'flex',
    textAlign: 'right',
  },
  navbarLogo: {
    height: '25px',
    paddingTop: '18px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  langColumn: { position: 'absolute', right: '0' },
  hyperlinks: { textDecoration: 'none' },
  impressumLink: {
    color: '#fff',
    fontSize: '14px',
  },
};
Object.freeze(styles);

const changeLanguage = (langCode) => {
  i18next.changeLanguage(langCode);
};

function Navbar(props) {
  const {
    checkIFrame,
    t,
    customerLinks,
    brandColorPreview,
    brandLogoPreview,
  } = props;

  const textColour = props.textColour || 'white'; // eslint-disable-line react/destructuring-assignment

  const brandColor = (brandColorPreview && brandColorPreview !== false)
    ? brandColorPreview
    : props.brandColor; // eslint-disable-line react/destructuring-assignment

  const logo = (brandLogoPreview && brandLogoPreview !== false)
    ? brandLogoPreview
    : props.logo; // eslint-disable-line react/destructuring-assignment
  // eslint-disable-next-line react/destructuring-assignment
  const impressumLink = props.impressumLink;
  if (checkIFrame === true) {
    return <span id="navbar" />;
  }

  const customerPortalNode = document.getElementById('customerPortalLink');
  const customerRegisterNode = document.getElementById('customerRegisterLink');

  let customerLinksDisplay = {
    customerRegister: 'block',
  };
  if (customerLinks.data !== null) {
    const { customerRegisterLink } = customerLinks.data;

    if (customerRegisterLink && customerRegisterLink !== null) {
      customerRegisterNode.href = `https://${customerRegisterLink}`;
    } else {
      customerLinksDisplay.customerRegister = 'none';
    }
  } else {
    customerLinksDisplay = {
      customerRegister: 'none',
    };
  }

  const LangContainer = styled.div`
    .dropbtn[type=checkbox] {
      background-color: ${brandColor};
      border: none;
      padding: 0;
      outline: none;
      cursor: pointer;
    }

    .dropbtn[type=checkbox]:checked ~ div .dropdown {
      background-color: rgb(255, 255, 255, 0.3);
    }

    .dropbtn[type=checkbox]:checked ~ .dropdown-content {
      display: block;
      font-size: 14px;
      font-weight: normal;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .dropbtn[type=checkbox]:checked ~ .dropdown-content span:hover {
      background-color: #22222210;
    }

    .dropbtn[type=checkbox]:checked ~ div #chevron {
      transition-duration: 0.2s;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(deg);
      transform: rotate(0deg);
    }

    .dropdown {
      position: relative;
      display: inline-block;
      height: 64px;
    }

    .dropdown-content {
      display: none;
      min-width: 136px;
      background-color: #FFFFFF;
      text-align: left;
      -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.12);
      max-height: 325px;
    }

    .dropdown-content span {
      color: black;
      padding: 16px 16px 16px 16px;
      -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
      text-decoration: none;
      display: block;
      cursor: pointer;
    }
  `;

  return (
    <Grid
      id="navbar"
      columns="equal"
      style={{ ...styles.navbar, backgroundColor: brandColor }}
    >
      <Grid.Row style={styles.navbarRow}>
        <Grid.Column>
          <Image src={logo} style={styles.navbarLogo} />
        </Grid.Column>
        <Grid.Column>
          <MapIcon title={t('topNavigationBar.findStations')} textColour={textColour} />
        </Grid.Column>
        <Grid.Column>
          <UserContainer>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              id="customerRegisterLink"
              style={{
                ...styles.hyperlinks,
                display: customerLinksDisplay.customerRegister,
                color: textColour,
              }}
              target="_blank"
              rel="noopener noreferrer"
              disabled="disabled"
            >
              <UserRegisterIcon title={t('topNavigationBar.register')} textColour={textColour} />
            </a>
          </UserContainer>
        </Grid.Column>
        <div style={{ position: 'absolute', right: 0, display: 'flex' }}>
          { impressumLink && (
          <Grid.Column>
            <div style={{ height: '64px', lineHeight: '64px', marginRight: '2rem' }}>
              <a style={styles.impressumLink} target="_blank" rel="noopener noreferrer" href={impressumLink}>{t('topNavigationBar.impressumLink')}</a>
            </div>
          </Grid.Column>
          ) }
          <Grid.Column>
            <LangContainer style={{ color: textColour }}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label style={{ cursor: 'pointer' }}>
                <input className="dropbtn" type="checkbox" style={{ position: 'absolute', top: '-9999px', left: '-9999px' }} />
                <Responsive as={Segment} minWidth={672}>
                  <div className="dropdown" style={{ width: '100%' }}>
                    <GlobeIcon language={i18next.language} />
                  </div>
                </Responsive>
                <Responsive as={Segment} maxWidth={672}>
                  <div className="dropdown">
                    <GlobeIcon language={i18next.language} />
                  </div>
                </Responsive>
                <div className="dropdown-content">
                  <span
                    onClick={() => {
                      changeLanguage('bg');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Български
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('cs');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Čeština
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('da');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Dansk
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('de');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Deutsch
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('et');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Eesti
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('en');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    English
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('es');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Español
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('fr');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Français
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('el');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Ελληνικά
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('hr');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Hrvatski
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('it');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Italiano
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('is');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Íslenska
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('hu');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Magyar
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('nb');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Norsk
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('pl');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Polski
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('ro');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Română
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('ru');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Русский
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('sk');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Slovenský
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('sr');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Srpski
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('fi');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Suomi
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('sv');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Svenska
                  </span>
                  <span
                    onClick={() => {
                      changeLanguage('th');
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    Thai
                  </span>
                </div>
              </label>
            </LangContainer>
          </Grid.Column>
        </div>
      </Grid.Row>
    </Grid>
  );
}

const UserContainer = styled.div`
  :hover {
    background-color: rgb(255, 255, 255, 0.3);
  }
`;

const mapStateToProps = (state) => {
  const { host, customerLinks } = state.customization;
  const brand = _.pick(host, ['logo', 'brandColor', 'impressumLink', 'textColour']);
  return { ...brand, customerLinks };
};

export default withTranslation()(connect(mapStateToProps)(Navbar));
