import {
  STATION_REQUEST,
  STATION_SUCCESS,
  STATION_FAILURE,
} from './types';

const defaultState = {
  is_fetching: false,
  data: null,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case STATION_REQUEST:
      return { ...defaultState, is_fetching: true };
    case STATION_SUCCESS:
      return {
        ...defaultState,
        data: action.station.MapBlocks,
      };
    case STATION_FAILURE:
      return { ...defaultState, error: action.error };
    default:
      return state;
  }
};
