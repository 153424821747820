function Time() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="white" height="14px">
      <path d="M16 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14zm0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4z" />
      <path d="M20.59 22L15 16.41V7h2v8.58l5 5.01L20.59 22z" />
    </svg>
  );
}

export default Time;
