import {
  INFORMATION_REQUEST,
  INFORMATION_SUCCESS,
  INFORMATION_FAILURE,
} from '../types';

export const createInformationRequestAction = () => ({
  type: INFORMATION_REQUEST,
});
export const createInformationSuccessAction = (information) => ({
  type: INFORMATION_SUCCESS,
  information,
});
export const createInformationFailureAction = (error) => ({
  type: INFORMATION_FAILURE,
  error,
});
