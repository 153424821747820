import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import ConnectorIcon from '../../assets/sidebar/ConnectorIcon';
import StatusButton from '../../assets/sidebar/StatusButton';
import StationsPreloader from '../../assets/sidebar/StationsPreloader';
import { childStationWidthCalculator } from '../../utils/childStationWidthCalculator';

const styles = {
  title: {
    fontSize: '13px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.38',
    letterSpacing: '-0.12px',
    color: '#494949',
  },
  evseContainer: {
    paddingTop: '5px',
  },
  connectorContainerSingleAvailable: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: '3px',
  },
  connectorContainerAvailable: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: '3px',
  },
  connectorContainerSingleNAvailable: {
    backgroundColor: '#edeff1',
    borderRadius: '5px',
    marginTop: '3px',
  },
  connectorContainerNAvailable: {
    backgroundColor: '#edeff1',
    borderRadius: '5px',
    marginTop: '3px',
  },
  connectorContainer: {
    border: '.5px solid rgba(210, 215, 220, 0.5)',
    marginTop: '3px',
  },
  connectorContainerRowAvailable: {
    display: 'flex',
    borderRadius: '5px',
  },
  connectorContainerRowNAvailable: {
    display: 'flex',
    border: '.5px solid rgba(210, 215, 220, 0.5)',
    borderRadius: '3px',
  },
  childConnector: {
    borderTop: '.5px solid rgba(210, 215, 220, 0.5)',
  },
  evseIdText: {
    fontFamily: 'IBM Plex Mono',
    fontSize: '15px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.37px',
    textAlign: 'center',
    color: '#222222',
    padding: '0 3px 0 3px',
  },
  evseMaxKWSign: {
    fontSize: '13px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.38',
    letterSpacing: '-0.12px',
  },
  evseMaxKWText: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.2px',
  },
  columnStationID: {
    paddingLeft: '17px',
  },
  columnStationConnectorIcon: {
    paddingLeft: '20px',
  },
  columnStationLabel: {
    paddingLeft: '16px',
  },
  StatusButton: {
    position: 'absolute',
    right: '30px',
  },
};
Object.freeze(styles);

const evseSelection = (evseId, status, title, brandColor) => {
  if (document.getElementById('footer').style.bottom === '0px' && document.getElementById(`evseSection${evseId}`).title !== ' ') {
    document.getElementById('footer').style.WebkitAnimation = 'animateKeyFrames 0.4s ease-out';
    document.getElementById('footer').style.animation = 'animateKeyFrames 0.4s ease-out';

    const element = document.getElementById('footer');
    const elementNewInstance = element.cloneNode(true);
    element.parentNode.replaceChild(elementNewInstance, element);
  } else {
    document.getElementById('footer').style.bottom = '0';
    document.getElementById('footer').style.transition = 'bottom 0.2s ease-out';
  }

  const evseSection = document.querySelectorAll('[id^=evseSection]');

  for (let i = 0; i < evseSection.length; i += 1) {
    evseSection[i].style.boxShadow = 'none';
    evseSection[i].style.boxSizing = 'none';
    evseSection[i].style.borderRadius = '0';
  }

  if (document.getElementById(`evseSection${evseId}`).title === ' ') {
    document.getElementById(`evseSection${evseId}`).style.boxShadow = 'none';
    document.getElementById(`evseSection${evseId}`).style.boxSizing = 'none';
    document.getElementById(`evseSection${evseId}`).style.borderRadius = '0';
    document.getElementById(`evseSection${evseId}`).removeAttribute('title');
    document.getElementById('footer').style.bottom = '-56px';
    document.getElementById('footer').style.transition = 'bottom 0.2s ease-out';
  } else {
    document.getElementById(`evseSection${evseId}`).style.boxShadow = 'inset 0px 0px 0px 2px #222222';
    document.getElementById(`evseSection${evseId}`).style.boxSizing = 'border-box';
    document.getElementById(`evseSection${evseId}`).style.borderRadius = '5px';

    const clickCheck = document.querySelectorAll('[title^=" "]');

    for (let x = 0; x < clickCheck.length; x += 1) {
      clickCheck[x].removeAttribute('title');
    }

    document.getElementById(`evseSection${evseId}`).title = ' ';
  }

  document.getElementById('footer').style.backgroundColor = brandColor;

  if (evseId !== false && status !== false) {
    const { REACT_APP_VIRTA_OTP_LINK } = process.env;
    document.getElementById('footerBackground').setAttribute('class', 'footerHover');
    document.getElementById('footerBackground').innerHTML = title;
    document.getElementById('footer').href = REACT_APP_VIRTA_OTP_LINK + evseId;
  } else {
    document.getElementById('footerBackground').setAttribute('class', 'footerDisabled');
    document.getElementById('footerBackground').innerHTML = title;
    document.getElementById('footer').removeAttribute('href');
  }
};

function Stations(props) {
  let i;
  const evsesData = [];
  const {
    data,
    title,
    brandColor,
    otpDisabledBtn,
    otpActiveBtn,
    statusText,
  } = props;

  if (data !== null) {
    i = 0;
    const { evses } = data;
    evses.map((evse) => {
      i += 1;
      const connectors = [];
      for (let x = 0; x < evse.connectors.length; x += 1) {
        let rowStyle = styles.connectorContainerRowNAvailable;
        let evseTextsColor = '#22222230';
        let evsekWlabelColor = evseTextsColor;
        if (evse.status === 1) {
          rowStyle = styles.connectorContainerRowAvailable;
          evseTextsColor = '#222222';
          evsekWlabelColor = '#494949';
        }
        if (x === 0) {
          connectors.push(
            <Grid.Row
              key={Math.floor(Math.random() * evse.id)}
              style={{
                ...rowStyle,
                alignItems: 'center',
                height: '64px',
              }}
            >
              <Grid.Column style={styles.columnStationID}>
                <div id={`pp${evse.id}`} style={{ ...styles.evseIdText, color: evseTextsColor, border: `2px solid ${evseTextsColor}` }}>{evse.id}</div>
              </Grid.Column>
              <Grid.Column style={styles.columnStationConnectorIcon}>
                <ConnectorIcon type={evse.connectors[x].type} iconColor={evseTextsColor} />
              </Grid.Column>
              <Grid.Column style={styles.columnStationLabel}>
                <span style={{ ...styles.evseMaxKWText, color: evseTextsColor }}>
                  {evse.connectors[x].maxKw}
                </span>
                <div style={{ ...styles.evseMaxKWSign, color: evsekWlabelColor }}>kW</div>
              </Grid.Column>
              <Grid.Column style={styles.StatusButton}>
                <StatusButton
                  status={evse.status}
                  statusText={statusText}
                />
              </Grid.Column>
            </Grid.Row>,
          );
        } else {
          const borderTopForDisabledEvses = evse.status !== 1 ? '0' : '.5px solid rgba(210, 215, 220, 0.5)';
          connectors.push(
            <Grid.Row
              key={Math.floor(Math.random() * evse.id)}
              style={{
                ...rowStyle,
                ...styles.childConnector,
                alignItems: 'center',
                height: '64px',
                borderTop: borderTopForDisabledEvses,
              }}
            >
              <Grid.Column style={{
                ...styles.columnStationID,
                width: childStationWidthCalculator(evse.id),
              }}
              />
              <Grid.Column style={styles.columnStationConnectorIcon}>
                <ConnectorIcon type={evse.connectors[x].type} iconColor={evseTextsColor} />
              </Grid.Column>
              <Grid.Column style={styles.columnStationLabel}>
                <span style={{ ...styles.evseMaxKWText, color: evseTextsColor }}>
                  {evse.connectors[x].maxKw}
                </span>
                <div style={{ ...styles.evseMaxKWSign, color: evsekWlabelColor }}>kW</div>
              </Grid.Column>
              <Grid.Column style={styles.StatusButton}>
                <StatusButton
                  status={evse.status}
                  statusText={statusText}
                />
              </Grid.Column>
            </Grid.Row>,
          );
        }
      }
      if (evse.status === 1) {
        if (evse.connectors.length === 1) {
          return evsesData.push(
            <Grid
              key={i}
              id={`evseSection${evse.id}`}
              onClick={() => {
                if (evse.oneTimePayment && evse.oneTimePayment === true) {
                  evseSelection(evse.id, true, otpActiveBtn, brandColor);
                } else {
                  evseSelection(evse.id, false, otpDisabledBtn, '#222222');
                }
              }}
              style={{
                ...styles.connectorContainerSingleAvailable,
                cursor: 'pointer',
              }}
            >
              <Container>{connectors}</Container>
            </Grid>,
          );
        }
        return evsesData.push(
          <Grid
            key={i}
            id={`evseSection${evse.id}`}
            onClick={() => {
              if (evse.oneTimePayment && evse.oneTimePayment === true) {
                evseSelection(evse.id, true, otpActiveBtn, brandColor);
              } else {
                evseSelection(evse.id, false, otpDisabledBtn, '#222222');
              }
            }}
            style={{
              ...styles.connectorContainerAvailable,
              cursor: 'pointer',
            }}
          >
            <Container>{connectors}</Container>
          </Grid>,
        );
      }
      if (evse.connectors.length === 1) {
        return evsesData.push(
          <Grid
            key={i}
            style={{
              ...styles.connectorContainerSingleNAvailable,
            }}
          >
            {connectors}
          </Grid>,
        );
      }
      return evsesData.push(
        <Grid
          key={i}
          style={{
            ...styles.connectorContainerSingleNAvailable,
          }}
        >
          {connectors}
        </Grid>,
      );
    });
  }
  if (_.isEmpty(evsesData)) {
    return <StationsPreloader />;
  }
  return (
    <DataFadeIn>
      <div id="datafadeIn">
        <div style={styles.title}>{title}</div>
        <div style={styles.evseContainer}>{evsesData}</div>
      </div>
    </DataFadeIn>
  );
}

const Container = styled.div`
  :hover {
    box-shadow: inset 0px 0px 0px 2px #22222270;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

const DataFadeIn = styled.div`
#datafadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const informationStateToProps = (state) => ({
  is_fetching: state.information.is_fetching,
  data: state.information.data,
});

export default connect(informationStateToProps)(Stations);
