export const childStationWidthCalculator = (number) => {
  let count = 0;

  let nmb = number;

  if (number >= 1) count += 1;

  while (nmb / 10 >= 1) {
    nmb /= 10;
    count += 1;
  }

  if (count < 2) return '19px';
  if (count < 3) return '29px';
  if (count < 4) return '38px';
  if (count < 5) return '47px';
  if (count < 6) return '57px';
  if (count < 7) return '66px';
  if (count < 8) return '76px';
  if (count < 9) return '85px';
  return '47px';
};

export default childStationWidthCalculator;
