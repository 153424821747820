import {
  HOST_REQUEST,
  HOST_SUCCESS,
  HOST_FAILURE,
} from '../types';

export const createHostRequestAction = () => ({
  type: HOST_REQUEST,
});
export const createHostSuccessAction = (host, latitudePreview, longitudePreview) => ({
  type: HOST_SUCCESS,
  host,
  latitudePreview,
  longitudePreview,
});
export const createHostFailureAction = (error, latitudePreview, longitudePreview) => ({
  type: HOST_FAILURE,
  error,
  latitudePreview,
  longitudePreview,
});
