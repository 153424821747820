import {
  createStatusRequestAction,
  createStatusSuccessAction,
  createStatusFailureAction,
} from './creators';

const fetchStatus = (stationIds, stations, clusters) => async (dispatch, getState) => {
  dispatch(createStatusRequestAction());

  try {
    const { REACT_APP_VIRTA_API_V4 } = process.env;
    const brandCode = getState().customization.host.brandCode || 'legacy-default';
    const apiHost = REACT_APP_VIRTA_API_V4.replace('{brandIdentity}', brandCode);

    const result = await fetch(`${apiHost}stations/status`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Source': `${brandCode}-web-map`,
      },
      body: JSON.stringify(stationIds),
    });

    if (result.ok) {
      const status = await result.json();

      dispatch(createStatusSuccessAction(status, stations, clusters));
    } else {
      throw new Error('Network response was not ok with fetching station status information');
    }
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createStatusFailureAction(error));
  }
};

export default fetchStatus;
