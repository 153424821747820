import styled from 'styled-components';

const Container = styled.div`
  .preloading {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #e1e1e1;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
  }
  @keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 1px
    }
  }
`;

const styles = {
  evseContainer: {
    paddingTop: '15px',
    textAlign: 'center',
  },
  topRectangle: {
    height: '7px',
    width: '100px',
    backgroundColor: '#e9e9e9',
    borderRadius: '4px',
  },
  evseRow: {
    marginTop: '3px',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '5px',
    height: '64px',
    alignItems: 'center',
  },
  leftRectangle: {
    marginLeft: '16px',
    borderRadius: '4px',
    height: '18px',
    width: '46px',
    backgroundColor: '#e9e9e9',
  },
  rightRectangle: {
    marginLeft: '20px',
    borderRadius: '4px',
    height: '30px',
    width: '56px',
    backgroundColor: '#e9e9e9',
  },
};
Object.freeze(styles);

function StationsPreloader() {
  return (
    <Container>
      <div style={styles.topRectangle} />
      <div style={styles.evseContainer}>
        <div style={styles.evseRow}>
          <div className="preloading" style={styles.leftRectangle} />
          <div className="preloading" style={styles.rightRectangle} />
        </div>
        <div style={styles.evseRow}>
          <div className="preloading" style={styles.leftRectangle} />
          <div className="preloading" style={styles.rightRectangle} />
        </div>
        <div style={styles.evseRow}>
          <div className="preloading" style={styles.leftRectangle} />
          <div className="preloading" style={styles.rightRectangle} />
        </div>
        <div style={styles.evseRow}>
          <div className="preloading" style={styles.leftRectangle} />
          <div className="preloading" style={styles.rightRectangle} />
        </div>
      </div>
    </Container>
  );
}

export default StationsPreloader;
