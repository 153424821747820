import {
  createBrandConfigRequestAction,
  createBrandConfigSuccessAction,
  createBrandConfigFailureAction,
} from './creators';
import { createCustomerLinksSuccessAction } from '../../../customer-links/action/creators';

const urlWithoutProtocol = (url) => {
  if (url.startsWith('http')) {
    const urlString = url.split('//');
    return urlString[1];
  }
  return url;
};

// eslint-disable-next-line default-param-last
const fetchBrandConfig = () => async (dispatch) => {
  const isBrandMgmt = window.location.pathname.startsWith('/map');
  if (!isBrandMgmt) {
    return;
  }
  try {
    dispatch(createBrandConfigRequestAction());

    await fetch('/api/brand/config')
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          throw new Error('Network response was not ok with fetching host information');
        }
        return response.json();
      }).then((config) => {
        dispatch(createBrandConfigSuccessAction(config));
        dispatch(createCustomerLinksSuccessAction({
          customerRegisterLink: `${urlWithoutProtocol(window.location.host)}/register`,
        }));
      });
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createBrandConfigFailureAction(error));
  }
};

export default fetchBrandConfig;
