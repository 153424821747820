import Bolt from './icons/bolt';
import Time from './icons/time';
import Error from './icons/error';

const active = {
  statusButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#00ad09',
    height: '24px',
    borderRadius: '12px',
    padding: '0 8px 0 8px',
  },
  statusButtonText: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.07px',
    textAlign: 'center',
    color: '#fafafa',
    paddingLeft: '5px',
  },
};
Object.freeze(active);

const inuse = {
  statusButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#207ed8',
    height: '24px',
    borderRadius: '12px',
    padding: '0 8px 0 8px',
  },
  statusButtonText: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.07px',
    textAlign: 'center',
    color: '#fafafa',
    paddingLeft: '5px',
  },
};
Object.freeze(inuse);

const offline = {
  statusButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#8c8c8c',
    height: '24px',
    borderRadius: '12px',
    padding: '0 8px 0 8px',
  },
  statusButtonText: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.07px',
    textAlign: 'center',
    color: '#fafafa',
    paddingLeft: '5px',
  },
};
Object.freeze(offline);

function StatusButton(props) {
  const { status, statusText } = props;

  switch (status) {
    case 1:
      return (
        <div style={active.statusButton}>
          <span>
            <Bolt />
          </span>
          <span style={active.statusButtonText}>{statusText.availbale}</span>
        </div>
      );
    case 2:
      return (
        <div style={inuse.statusButton}>
          <span>
            <Time />
          </span>
          <span style={inuse.statusButtonText}>{statusText.inUse}</span>
        </div>
      );
    case 3:
      return (
        <div style={offline.statusButton}>
          <span>
            <Error />
          </span>
          <span style={offline.statusButtonText}>{statusText.disabled}</span>
        </div>
      );
    default:
      return (
        <div style={offline.statusButton}>
          <span>
            <Error />
          </span>
          <span style={offline.statusButtonText}>{statusText.disabled}</span>
        </div>
      );
  }
}

export default StatusButton;
