import _ from 'lodash';
import {
  createCustomerLinksRequestAction,
  createCustomerLinksSuccessAction,
  createCustomerLinksFailureAction,
} from './creators';

const trueUrlCreator = (url) => {
  if (url.startsWith('http')) {
    const urlString = url.split('//');
    return urlString[1];
  }
  return url;
};

const customerLinksSplitter = (brandData) => {
  let customerRegisterLink = null;
  let customerPortalLink = null;
  brandData.services.forEach((service) => {
    if (service.type === 'register') {
      if (brandData.settings.brandMgmtDomain && brandData.settings.brandMgmtDomain.includes('https://')) {
        customerRegisterLink = trueUrlCreator(brandData.settings.brandMgmtDomain);
      } else if (
        service?.brand?.settings?.brandMgmtDomain
        && service.brand.settings.brandMgmtDomain.includes('https://')
      ) {
        customerRegisterLink = trueUrlCreator(service.brand.settings.brandMgmtDomain);
      } else if (service.domain !== null) {
        customerRegisterLink = trueUrlCreator(service.domain);
      } else {
        customerRegisterLink = trueUrlCreator(service.parkedUrl);
      }
    }

    if (service.type === 'customer') {
      if (service.domain !== null) {
        customerPortalLink = trueUrlCreator(service.domain);
      } else {
        customerPortalLink = trueUrlCreator(service.parkedUrl);
      }
    }
  });
  return { customerRegisterLink, customerPortalLink };
};

const fetchCustomerLinks = () => async (dispatch, getState) => {
  const isBrandMgmt = window.location.pathname.startsWith('/map');
  if (isBrandMgmt) {
    return;
  }

  dispatch(createCustomerLinksRequestAction());

  try {
    const { REACT_APP_VIRTAGLOBAL_API } = process.env;
    let result = await fetch(
      `${REACT_APP_VIRTAGLOBAL_API}brands/${
        getState().customization.host.brandCode}`,
    );

    if (result.ok) {
      let brand = await result.json();
      let brandCustomerLinks = customerLinksSplitter(brand);

      if (
        _.isEmpty(brandCustomerLinks.customerRegisterLink)
          && _.isEmpty(brandCustomerLinks.customerPortalLink)
      ) {
        const { parkedUrl } = getState().customization.host;
        const parkedUrlLowerCase = parkedUrl.toLowerCase();
        const url = trueUrlCreator(parkedUrlLowerCase);

        result = await fetch(`${REACT_APP_VIRTAGLOBAL_API}hosts/${url}`);
        let parkedUrlBrandCode = await result.json();
        parkedUrlBrandCode = parkedUrlBrandCode.service.brand_code;
        result = await fetch(
          `${REACT_APP_VIRTAGLOBAL_API}brands/${parkedUrlBrandCode}`,
        );
        brand = await result.json();
        brandCustomerLinks = customerLinksSplitter(brand);
      }

      dispatch(createCustomerLinksSuccessAction(brandCustomerLinks));
    } else {
      throw new Error('Network response was not ok with fetching customer links');
    }
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createCustomerLinksFailureAction(error));
  }
};

export default fetchCustomerLinks;
