import { connect } from 'react-redux';
import _ from 'lodash';
import { Responsive, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { Image } from 'load-image-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sliderStyles = {
  images: {
    width: '100%',
    height: '251px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  dotButtonsDesktop: {
    position: 'absolute',
    top: '230px',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  dotButtonsMobile: {
    position: 'absolute',
    top: '18%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Object.freeze(sliderStyles);

function Images(props) {
  let i;
  const picturesDesktop = [];
  const picturesMobile = [];
  const { data } = props;
  if (data !== null) {
    i = 0;
    data.pictures.forEach((pic) => {
      i += 1;
      picturesDesktop.push(
        <SliderContainerDesktop key={i}>
          <Image
            src={pic}
            loadOptions={{
              aspectRatio: 3 / 2,
            }}
          />
        </SliderContainerDesktop>,
      );
      picturesMobile.push(
        <SliderContainerMobile key={i}>
          <Image
            src={pic}
            loadOptions={{
              aspectRatio: 16 / 9,
            }}
          />
        </SliderContainerMobile>,
      );
    });
  }

  if (_.isEmpty(picturesDesktop || picturesMobile)) {
    return null;
  }

  const settings = {
    dots: true,
  };

  return (
    <DataFadeIn>
      <div id="datafadeIn">
        <Responsive as={Segment} minWidth={600}>
          <Container>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Slider {...settings}>
              {picturesDesktop}
            </Slider>
          </Container>
        </Responsive>
        <Responsive as={Segment} maxWidth={599}>
          <Container>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Slider {...settings}>
              {picturesMobile}
            </Slider>
          </Container>
        </Responsive>
      </div>
    </DataFadeIn>
  );
}

const Container = styled.div`
  .slick-slide {
    height: 251px;
  }
  .slick-dots {
    bottom: 0;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots li.slick-active {
    color: #222222 !important;
  }
  .slick-dots li.slick-active button:before {
    color: #222222 !important;
    opacity: 1;
  }
  .slick-dots li {
    color: #FFFFFF !important;
    opacity: 1;
  }
  .slick-dots li button:before {
    color: #FFFFFF !important;
    opacity: 1;
  }
`;

const SliderContainerDesktop = styled.div`
  a {
    pointer-events: none;
  }
  canvas {
    height: 251px;
    width: 375px;
  }
`;

const SliderContainerMobile = styled.div`
  a {
    pointer-events: none;
  }
  canvas {
    height: 251px;
  }
`;

const DataFadeIn = styled.div`
#datafadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const informationStateToProps = (state) => ({
  is_fetching: state.information.is_fetching,
  data: state.information.data,
});

export default connect(informationStateToProps)(Images);
