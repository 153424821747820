function Bolt() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="white"
      height="14px"
    >
      <path d="M19 30a1 1 0 0 1-.79-.39l-13-17a1 1 0 0 1-.11-1A1 1 0 0 1 6 11h4.75L9 3.22a1 1 0 0 1 .2-.85A1 1 0 0 1 10 2h10a1 1 0 0 1 1 .77l3 13A1 1 0 0 1 23 17h-4.83L20 28.85a1 1 0 0 1-.6 1.07 1.09 1.09 0 0 1-.4.08zM8 13l9.4 12.28-1.4-9.13A1 1 0 0 1 17 15h4.74L19.2 4h-7.95L13 11.78a1 1 0 0 1-.2.85 1 1 0 0 1-.8.37z" />
    </svg>
  );
}

export default Bolt;
