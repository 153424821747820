const mapblocksAndZoomLevelArray = (
  indexLatMin,
  indexLatMax,
  indexLngMin,
  indexLngMax,
  zoomLevel,
) => ({
  KeyRanges: [
    {
      IndexLatMin: indexLatMin,
      IndexLatMax: indexLatMax,
      IndexLngMin: indexLngMin,
      IndexLngMax: indexLngMax,
      IsForDisplay: true,
      ZoomLevel: zoomLevel,
    },
  ],
  Filters: {
    EvseProviders: [
      'Virta',
      // We don't need Hubject stations at this moment
      // "Hubject"
    ],
    // We can remove this part to getting all types
    // 'EvseConnectorTypes': [
    //     "Mennekes",
    //     "CHAdeMO",
    //     "Type 1",
    //     "Type3",
    //     "CCS"
    // ]
  },
});

export default mapblocksAndZoomLevelArray;
