const realLanguageNamedetector = (langCode) => {
  let languageName;

  switch (langCode) {
    case 'fi':
      languageName = 'Suomi';
      break;
    case 'en':
      languageName = 'English';
      break;
    case 'it':
      languageName = 'Italiano';
      break;
    case 'fr':
      languageName = 'Français';
      break;
    case 'is':
      languageName = 'Íslenska';
      break;
    case 'sv':
      languageName = 'Svenska';
      break;
    case 'de':
      languageName = 'Deutsch';
      break;
    case 'nb':
      languageName = 'Norsk';
      break;
    case 'cs':
      languageName = 'Čeština';
      break;
    case 'hu':
      languageName = 'Magyar';
      break;
    case 'ro':
      languageName = 'Română';
      break;
    case 'sk':
      languageName = 'Slovenský';
      break;
    case 'es':
      languageName = 'Español';
      break;
    case 'pl':
      languageName = 'Polski';
      break;
    case 'bg':
      languageName = 'Български';
      break;
    case 'et':
      languageName = 'Eesti';
      break;
    case 'da':
      languageName = 'Dansk';
      break;
    case 'el':
      languageName = 'Ελληνικά';
      break;
    case 'ru':
      languageName = 'Русский';
      break;
    case 'th':
      languageName = 'Thai';
      break;
    case 'sr':
      languageName = 'Srpski';
      break;
    case 'hr':
      languageName = 'Hrvatski';
      break;
    default:
      languageName = 'English';
      break;
  }
  return languageName;
};

export default realLanguageNamedetector;
