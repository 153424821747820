import { find, get } from 'lodash';
import {
  BRAND_CONFIG_REQUEST,
  BRAND_CONFIG_SUCCESS,
  BRAND_CONFIG_FAILURE,
} from './types';
import convertWebappStringToLatLngObject from '../../../../utils/geometry';

const {
  REACT_APP_VIRTA_DEFAULT_CENTERPOINT,
  REACT_APP_GOOGLEPLAY_LINK,
  REACT_APP_APPSTORE_LINK,
} = process.env;
const defaultCenterPoint = convertWebappStringToLatLngObject(REACT_APP_VIRTA_DEFAULT_CENTERPOINT);

const defaultState = {
  is_fetching: false,
  brandColor: '#cccccc',
  textColor: '#FFFFFF',
  brandName: 'virta',
  logo: null,
  googlePlayStoreLink: REACT_APP_GOOGLEPLAY_LINK,
  appleAppStoreLink: REACT_APP_APPSTORE_LINK,
  brandCode: 'virta',
  parkedUrl: '/map',
  defaultLocale: 'en',
  webAppCenter: defaultCenterPoint,
  error: null,
  impressumLink: null,
};

const calculatePrimaryTextColor = (color) => {
  const hex = color.replace('#', '');
  const rgb = parseInt(hex, 16);
  const r = (rgb >> 16) & 0xff; // eslint-disable-line no-bitwise
  const g = (rgb >> 8) & 0xff; // eslint-disable-line no-bitwise
  const b = (rgb >> 0) & 0xff; // eslint-disable-line no-bitwise

  const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const textColor = brightness < 128 ? '#ffffff' : '#000000';

  return textColor;
};

// eslint-disable-next-line default-param-last
export const BrandConfig = (state = defaultState, action) => {
  switch (action.type) {
    case BRAND_CONFIG_REQUEST:
      return { ...defaultState, is_fetching: true };
    case BRAND_CONFIG_SUCCESS: {
      const logoObj = find(action.config.images, ['type', 'LOGO']);
      const brandColourObj = find(action.config.colours, ['type', 'PRIMARY']);
      return {
        ...defaultState,
        brandColor: brandColourObj.hexCode,
        textColor: calculatePrimaryTextColor(brandColourObj.hexCode),
        logo: logoObj.url,
        brandName: action.config.name,
        brandCode: 'virta',
        parkedUrl: '/',
      };
    }
    case BRAND_CONFIG_FAILURE:
      // eslint-disable-next-line no-case-declarations,max-len
      return { ...defaultState, error: action.error };
    default:
      return state;
  }
};

export default BrandConfig;
