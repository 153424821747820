/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps';
import { connect } from 'react-redux';
import Markers from './Markers';
import Clusters from './Clusters';
import mapStyles from '../assets/mapStyles.json';
import mapblocksAndZoomLevelArray from '../utils/createMapblocksAndZoomLevelArray';
import fetchStation from '../store/station/action/fetchStation';
import screenDiameterCalculationInCm from '../utils/diameterCalculationInCm';
import distanceCalculationInMeter from '../utils/distanceCalculationInMeter';
import zoomLevelCalculationViaDistances from '../utils/zoomLevelCalculation';
import {
  MapBlockKeyIndexLatFrom,
  MapBlockKeyIndexLngFrom,
} from '../utils/mapBlockCalculation';
import i18next from '../locales';

let mapReference = null;

const Elements = {
  googleMapURL:
    `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  containerElement: (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        Bottom: '64px',
      }}
    />
  ),
  mapElement: <div style={{ height: '100%' }} />,
  loadingElement: <div style={{ height: '100%' }} />,
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultLang: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate() {
    const { defaultLocale } = this.props;
    const { defaultLang } = this.state;
    if (defaultLang === false) {
      i18next.changeLanguage(defaultLocale);
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ defaultLang: true });
    }
  }

  render() {
    const {
      stations,
      statuses,
      checkIFrame,
      center,
      brandColorPreview,
    } = this.props;

    const onMapIdle = () => {
      const { station } = this.props;

      const bounds = mapReference.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const distanceInM = distanceCalculationInMeter(
        ne.lat(),
        ne.lng(),
        sw.lat(),
        sw.lng(),
      );
      const distanceInCm = screenDiameterCalculationInCm(
        document.getElementById('map').offsetWidth,
        document.getElementById('map').offsetHeight,
      );

      const zoomLevel = zoomLevelCalculationViaDistances(
        distanceInCm,
        distanceInM,
      );

      const neLatBlock = MapBlockKeyIndexLatFrom(
        ne.lat(),
        zoomLevelCalculationViaDistances(distanceInCm, distanceInM),
      );
      const swLatBlock = MapBlockKeyIndexLatFrom(
        sw.lat(),
        zoomLevelCalculationViaDistances(distanceInCm, distanceInM),
      );

      const neLngBlock = MapBlockKeyIndexLngFrom(
        ne.lng(),
        zoomLevelCalculationViaDistances(distanceInCm, distanceInM),
      );
      const swLngBlock = MapBlockKeyIndexLngFrom(
        sw.lng(),
        zoomLevelCalculationViaDistances(distanceInCm, distanceInM),
      );

      const indexLatMin = neLatBlock < swLatBlock ? neLatBlock : swLatBlock;
      const indexLatMax = neLatBlock < swLatBlock ? swLatBlock : neLatBlock;

      const indexLngMin = neLngBlock < swLngBlock ? neLngBlock : swLngBlock;
      const indexLngMax = neLngBlock < swLngBlock ? swLngBlock : neLngBlock;

      const mapBlocksAndZoomlevel = mapblocksAndZoomLevelArray(
        indexLatMin,
        indexLatMax,
        indexLngMin,
        indexLngMax,
        zoomLevel,
      );

      station(mapBlocksAndZoomlevel);
    };

    return (
      <div>
        <div id="map">
          <GoogleMapVirta
            center={center}
            {...Elements}
            onMapIdle={onMapIdle}
            stations={stations}
            statuses={statuses}
            checkIFrame={checkIFrame}
            brandColorPreview={brandColorPreview}
          />
        </div>
      </div>
    );
  }
}

const defaultMapOptions = {
  styles: mapStyles,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  minZoom: 4,
  maxZoom: 22,
};

const GoogleMapVirta = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        mapReference = map;
      }}
      onIdle={props.onMapIdle}
      defaultCenter={props.center}
      center={props.center}
      defaultZoom={13}
      defaultOptions={defaultMapOptions}
    >
      { mapReference && <Clusters map={mapReference} /> }
      { mapReference && (
      <Markers
        checkIFrame={props.checkIFrame}
        map={mapReference}
        brandColorPreview={props.brandColorPreview}
      />
      )}
    </GoogleMap>
  )),
);

const mapStateToProps = (state) => ({
  is_fetching: state.station.is_fetching,
  center: state.customization.host.webAppCenter,
  defaultLocale: state.customization.host.defaultLocale,
});

const mapDispatchToProps = (dispatch) => ({
  station: (data) => dispatch(fetchStation(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Map);
