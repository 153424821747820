import {
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from '../types';

export const createStatusRequestAction = () => ({ type: STATUS_REQUEST });
export const createStatusSuccessAction = (status, stations, clusters) => ({
  type: STATUS_SUCCESS,
  status,
  stations,
  clusters,
});
export const createStatusFailureAction = (error) => ({
  type: STATUS_FAILURE,
  error,
});
