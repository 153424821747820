import {
  INFORMATION_REQUEST,
  INFORMATION_SUCCESS,
  INFORMATION_FAILURE,
} from './types';

const defaultState = {
  data: null,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case INFORMATION_REQUEST:
      return { ...defaultState, is_fetching: true };
    case INFORMATION_SUCCESS:
      return {
        ...defaultState,
        data: action.information,
      };
    case INFORMATION_FAILURE:
      return { ...defaultState, error: action.error };
    default:
      return state;
  }
};
