import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Map from './component/Map';
import createStore from './store';
import Navbar from './component/Navbar';
import fetchBrandConfig from './store/customization/host/brand-mgmt/action/fetchBrandConfig';

const store = createStore();

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Map />
        <Navbar />
      </Provider>
    </Suspense>
  );
}

store.dispatch(fetchBrandConfig());

export default withTranslation()(App);
