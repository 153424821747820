import { Responsive, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import realLanguageNamedetector from '../utils/realLanguageNamedetector';

const styles = {
  containerDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 16px 0 16px',
    height: '64px',
  },
  containerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 12px 0 12px',
    height: '64px',
  },
  title: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
    padding: '0 8px 0 8px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.12px',
  },
};
Object.freeze(styles);

function GlobeIcon(props) {
  const { language } = props;
  return (
    <div>
      <Responsive as={Segment} minWidth={672} style={styles.containerDesktop}>
        <svg
          fill="white"
          height="24px"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M14 4a7 7 0 1 1-7 7 7 7 0 0 1 7-7m0-2a9 9 0 1 0 9 9 9 9 0 0 0-9-9z" />
          <path d="M28 11a13.92 13.92 0 0 0-4.11-9.89l-1.41 1.41a12 12 0 0 1-16.92 17l-1.45 1.37A13.92 13.92 0 0 0 14 25v3h-4v2h10v-2h-4v-3.16A14 14 0 0 0 28 11z" />
        </svg>
        <p style={styles.title}>{realLanguageNamedetector(language)}</p>
        <Container>
          <svg
            id="chevron"
            fill="white"
            height="24px"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path d="M16 22L6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z" />
          </svg>
        </Container>
      </Responsive>
      <Responsive as={Segment} maxWidth={672} style={styles.containerMobile}>
        <svg
          fill="white"
          height="21px"
          width="21px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M14 4a7 7 0 1 1-7 7 7 7 0 0 1 7-7m0-2a9 9 0 1 0 9 9 9 9 0 0 0-9-9z" />
          <path d="M28 11a13.92 13.92 0 0 0-4.11-9.89l-1.41 1.41a12 12 0 0 1-16.92 17l-1.45 1.37A13.92 13.92 0 0 0 14 25v3h-4v2h10v-2h-4v-3.16A14 14 0 0 0 28 11z" />
        </svg>
      </Responsive>
    </div>
  );
}

const Container = styled.div`
  #chevron {
    transition-duration: 0.2s;
    -ms-transform: rotate(180deg);
    -ms-transform-origin: center center;
    -webkit-transform: rotate(180deg);
    -webkit-transform-origin: center center;
    transform: rotate(180deg);
    transform-origin: center center;
  }
`;

export default GlobeIcon;
