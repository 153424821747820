import {
  createInformationRequestAction,
  createInformationSuccessAction,
  createInformationFailureAction,
} from './creators';

const fetchInformation = (station) => async (dispatch, getState) => {
  dispatch(createInformationRequestAction());

  try {
    const { REACT_APP_VIRTA_API_V4 } = process.env;
    const brandCode = getState().customization.host.brandCode || 'legacy-default';
    const apiHost = REACT_APP_VIRTA_API_V4.replace('{brandIdentity}', brandCode);

    const result = await fetch(`${apiHost}stations/${station}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Source': `${brandCode}-web-map`,
      },
    });

    if (result.ok) {
      const information = await result.json();
      dispatch(createInformationSuccessAction(information));
    } else {
      throw new Error('Network response was not ok with fetching station information');
    }
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createInformationFailureAction(error));
  }
};

export default fetchInformation;
