import {
  STATION_REQUEST,
  STATION_SUCCESS,
  STATION_FAILURE,
} from '../types';

export const createStationRequestAction = () => ({
  type: STATION_REQUEST,
});
export const createStationSuccessAction = (station) => ({
  type: STATION_SUCCESS,
  station,
});
export const createStationFailureAction = (error) => ({
  type: STATION_FAILURE,
  error,
});
