import _ from 'lodash';
import {
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from './types';

const defaultState = {
  is_fetching: false,
  data: null,
  cluster: null,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case STATUS_REQUEST:
      return { ...state, is_fetching: true, error: null };
    case STATUS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const determineStatus = (evseId) => {
        // eslint-disable-next-line max-len,no-shadow
        const evse = action.status.reduce((acc, status) => acc || status.evses.find((evse) => evse.id === evseId), undefined);
        if (_.isNil(evse)) {
          return false;
        }
        return evse.status;
      };

      // eslint-disable-next-line no-case-declarations
      const result = action.stations.map((station) => ({
        ...station,
        evses: station.evses.map((evse) => ({
          ...evse,
          status: determineStatus(evse.id),
        })),
      }));

      // eslint-disable-next-line no-case-declarations
      const stations = result.map((station) => ({
        ...station,
        status: station.evses.reduce((acc, evse) => {
          if (acc === 1 || evse.status === 1) {
            return 1;
          } if (acc === 2 || evse.status === 2) {
            return 2;
          }
          return acc;
        }, 0),
      }));
      return { ...defaultState, data: stations, cluster: action.clusters };
    case STATUS_FAILURE:
      return { ...defaultState, error: action.error };
    default:
      return state;
  }
};
